import React, { useMemo, useState } from 'react';
import { Image } from '~/shared/components';
import { StyledImageWrapper } from './Styled';
import { queriesIsolated } from '~/theme';
import { ProductImageViewModel } from '~/lib/data-contract';
import { ImageProps } from '../Image/Image';

export type Variant = 'fallback' | 'product' | 'model';

type Props = {
    image?: ProductImageViewModel;
    index?: number;
} & Omit<ImageProps, 'src'>;

/**
 * HOC that adds a wrapper around the image component
 * Which handles specific styling based on image type
 * @returns
 */
export const ProductImage = ({ image, index, priority, onLoadCallback, alt, ...rest }: Props) => {
    const isPriority = (index !== undefined && index < 8) || !!priority;
    const fallbackImage = '/assets/fallback-product.svg';

    const [source, setSource] = useState(image?.url ?? fallbackImage);

    // Image variant
    const variant = useMemo(() => getVariant(image), [image]);

    // Default sizes
    const sizes = `${queriesIsolated.xs} 50vw, ${queriesIsolated.sm} 30vw, ${queriesIsolated.md} 22vw, 16vw`;

    return (
        <StyledImageWrapper variant={variant}>
            <Image
                src={source}
                alt={alt || ''}
                sizes={sizes}
                width={2}
                height={3}
                // Make sure we get 2:3 aspect ratio if the image is a model image
                cW={variant === 'model' ? 2 : undefined}
                cH={variant === 'model' ? 3 : undefined}
                priority={isPriority}
                skeletonShade="none"
                onLoadCallback={() => {
                    onLoadCallback?.();
                }}
                onError={() => setSource(fallbackImage)}
                {...rest}
            />
        </StyledImageWrapper>
    );
};

/**
 * Get image variant id based on image label
 * default: product
 * @param image
 * @returns
 */
const getVariant = (image?: ProductImageViewModel): Variant | undefined => {
    const { label, url } = image || {};
    if (!url) {
        return 'fallback';
    }
    if (label === 'Product_detail') {
        return;
    }
    if (label?.startsWith('Product')) {
        return 'product';
    }
    if (label?.startsWith('Model')) {
        return 'model';
    }
    return 'product';
};
