import styled from '@emotion/styled';
import { Text } from '~/shared/components';
import { switchProp } from 'styled-tools';

export interface StyledPriceGroupProps {
    orientation?: 'horizontal' | 'vertical-bottom' | 'vertical-top';
}

interface PriceProps {
    modifier: 'original' | 'discounted' | null;
}

export const StyledPriceGroup = styled.div<StyledPriceGroupProps>(
    () => ({
        display: 'flex',
    }),

    switchProp('orientation', {
        horizontal: ({ theme }) => ({
            columnGap: theme.spaces[3],
            flexWrap: 'wrap',
        }),
        'vertical-top': () => ({
            flexDirection: 'column-reverse',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        }),
        'vertical-bottom': () => ({
            flexDirection: 'column-reverse',
            alignItems: 'flex-end',
        }),
    })
);

export const StyledPrice = styled(Text.withComponent('span'))<PriceProps>(
    ({ theme: { priceGroup } }) => ({
        color: priceGroup.colors.default,
        fontWeight: priceGroup.weight.default,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),

    switchProp('modifier', {
        original: ({ theme: { priceGroup } }) => ({
            textDecoration: 'line-through',
            color: priceGroup.colors.original,
            fontWeight: priceGroup.weight.original,
        }),
        discounted: ({ theme: { priceGroup } }) => ({
            color: priceGroup.colors.discount,
            fontWeight: priceGroup.weight.discount,
        }),
    })
);
