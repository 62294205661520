import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { ImageTagViewModel } from '~/lib/data-contract';
import { Text, TextProps } from '$shared/components/Text';
import { breakpoints, pxToEm, pxToRem } from '~/theme';

export type Variants = 'Campaign' | 'Price' | 'Attribute';

type Props = Partial<
    Pick<ImageTagViewModel, 'backgroundColor' | 'fontColor' | 'placeholder'> & {
        stylisticVariant: string;
    }
> &
    Omit<TextProps, 'variant'>;

// Styling priority:
// Specific hex colors
// Variants
export const StyledTag = styled(Text)<Props>(
    ({ theme, backgroundColor, fontColor }) => ({
        padding: `${pxToRem(3)} ${pxToRem(6)}`,
        fontWeight: theme.text.weights.bold,
        backgroundColor,
        color: fontColor,
        fontSize: theme.fontSizes.xs,
        letterSpacing: pxToEm('0.2px'),
        [breakpoints.md]: {
            fontSize: theme.fontSizes.sm,
            padding: `${theme.spaces[1]} ${theme.spaces[3]}`,
        },
    }),
    switchProp('stylisticVariant', {
        Campaign: ({ theme }) => ({
            backgroundColor: theme.colors.dark,
            color: theme.colors.light,
        }),
        Price: ({ theme }) => ({
            backgroundColor: theme.colors.campaign,
            color: theme.colors.light,
        }),
        Attribute: ({ theme }) => ({
            backgroundColor: theme.colors.light30,
            color: theme.colors.dark,
            letterSpacing: theme.letterSpacings.xs,
            [breakpoints.md]: {
                padding: `${pxToEm(2)} ${pxToEm(6)}`,
                fontSize: theme.fontSizes.xs,
                letterSpacing: theme.letterSpacings.md,
            },
        }),
    })
);
