import React from 'react';
import { ImageTagViewModel, ProductPriceViewModel } from '~/lib/data-contract';
import { TextVariants } from '~/shared/components';
import { useFrame, useFranchise, useTranslation } from '~/shared/utils';
import { VisuallyHidden } from '../VisuallyHidden';
import { StyledPriceGroup, StyledPrice, StyledPriceGroupProps } from './styled';
import { ProductTags } from '$shared/components/ProductTags';
import { Spacer } from '$theme';

export type PriceGroupProps = Pick<
    ProductPriceViewModel,
    'displayPrice' | 'displayUnitPrice' | 'displaySalePrice' | 'isOnSale' | 'currency'
> &
    StyledPriceGroupProps & {
        variant?: TextVariants;
        imageTags?: ImageTagViewModel[];
        isPDP?: boolean;
    };

export const PriceGroup = ({
    displaySalePrice,
    displayPrice,
    displayUnitPrice,
    isOnSale,
    variant = 'body',
    orientation = 'horizontal',
    imageTags,
    isPDP,
}: PriceGroupProps) => {
    const { translate } = useTranslation();
    const { activeMarket } = useFranchise();
    const { data: frameData } = useFrame();

    return (
        <>
            <StyledPriceGroup orientation={orientation}>
                {isOnSale ? (
                    <StyledPrice modifier={'discounted'} variant={variant}>
                        <VisuallyHidden children={translate('price.current')} />
                        <span className="price-selector-on-sale">{displaySalePrice}</span>
                    </StyledPrice>
                ) : null}
                <StyledPrice modifier={isOnSale ? 'original' : null} variant={variant}>
                    <VisuallyHidden
                        children={translate(`price.${isOnSale ? 'previous' : 'current'}`)}
                    />
                    <span className="price-selector">{displayPrice || displayUnitPrice}</span>
                </StyledPrice>
                {imageTags && imageTags.length > 0 && (
                    <ProductTags productTags={imageTags} isCampaign />
                )}
            </StyledPriceGroup>
            {frameData?.ecommerce?.anyDayWidget?.enable &&
                frameData?.ecommerce?.anyDayWidget?.pricetagToken &&
                isPDP &&
                activeMarket?.currency === 'DKK' && (
                    <>
                        <Spacer xs="2" md="2" />
                        <anyday-price-widget
                            theme="outline"
                            currency={activeMarket.currency}
                            price-selector={
                                !isOnSale ? '.price-selector' : '.price-selector-on-sale'
                            }
                            price-format-locale={`${activeMarket?.language}-${activeMarket?.countryKey}`}
                            locale={`${activeMarket?.language}-${activeMarket?.countryKey}`}
                            token={frameData.ecommerce.anyDayWidget.pricetagToken}
                            fully-clickable="true"
                            container-breakpoint="350"
                            debug="info"
                        ></anyday-price-widget>
                    </>
                )}
        </>
    );
};
