import React, { useMemo } from 'react';
import { ImageTagViewModel } from '~/lib/data-contract';
import { StyledTagList, Layout } from './styled';
import { weakKey } from '~/shared/utils/jsx';
import { Tag } from './components/Tag';
import { useTranslation } from '~/shared/utils';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { Spacer } from '~/theme';
import { Theme } from '@emotion/react';

type Props = {
    productTags?: ImageTagViewModel[];
    selectFrom?: 'bottom' | 'top' | 'all';
    layout?: Layout;
    isCampaign?: boolean;
    /**
     * Adds top and or bottom space
     * [top spacing key, bottom spacing key]
     */
    spacing?: [keyof Theme['spaces'] | null, keyof Theme['spaces'] | null];
};

export const ProductTags = ({
    productTags = [],
    selectFrom = 'all',
    layout = 'list',
    isCampaign = false,
    spacing,
}: Props) => {
    const { translate } = useTranslation();
    const isAttribute = selectFrom === 'bottom';

    const filteredTags = useMemo(() => {
        if (selectFrom === 'all') {
            return productTags.filter(
                (tag) => ['Campaign', 'Price'].includes(tag.variant || '') === isCampaign
            );
        }

        return productTags.filter(
            (tag) =>
                tag.placeholder?.toLowerCase().includes(selectFrom) &&
                ['Campaign', 'Price'].includes(tag.variant || '') === isCampaign
        );
    }, [selectFrom, productTags, isCampaign]);

    const Tags = useMemo(
        () =>
            filteredTags.map(({ variant, text, ...restProps }) => (
                <Tag
                    key={weakKey(restProps)}
                    stylisticVariant={isAttribute ? 'Attribute' : variant}
                    as="span"
                    variant={isAttribute ? 'overline' : 'bodySm'}
                    {...restProps}
                >
                    {text}
                </Tag>
            )),
        [filteredTags, isAttribute]
    );

    if (!Tags.length) {
        return <></>;
    }

    return (
        <>
            {spacing?.[0] ? <Spacer space={spacing[0]} /> : null}
            <StyledTagList layout={layout} as={isAttribute ? 'dl' : 'ul'}>
                {isAttribute ? (
                    <VisuallyHidden>
                        <dt>{translate('accessibility.productAttributes')}</dt>
                    </VisuallyHidden>
                ) : null}
                {Tags.map((tag) => {
                    const NodeTag = isAttribute ? 'dd' : 'li';
                    return <NodeTag key={weakKey(tag)}>{tag}</NodeTag>;
                })}
            </StyledTagList>
            {spacing?.[1] ? <Spacer space={spacing[1]} /> : null}
        </>
    );
};
