import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { cover } from '~/shared/utils/styled';
import { Variant } from './ProductImage';

export const StyledImageWrapper = styled.div<{
    variant?: Variant;
}>(
    ({ theme }) => ({
        position: 'relative',
        height: '100%',
        backgroundColor: theme.colors.light,
    }),
    switchProp('variant', {
        product: ({ theme }) => ({
            // Workaround until layout="raw" is not experimental anymore
            img: {
                padding: `0 clamp(${theme.spaces[2]}, 5%, ${theme.spaces[4]})!important;`,
            },
            '&:after': {
                content: '""',
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                ...cover,
            },
        }),
        fallback: ({ theme }) => ({
            // Blend with fallback image
            img: {
                backgroundColor: theme.colors.light30,
            },
        }),
    })
);
